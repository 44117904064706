import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { styled } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantError {
    background-color: #d88070 !important;
  }
  &.SnackbarItem-variantInfo {
    background-color: #e0d8c8 !important;
    color: #6f6c65;
  }
  &.SnackbarItem-variantSuccess {
    background-color: #96ab80 !important;
  }
  &.SnackbarItem-contentRoot {
    font-family: Agrandir;
    font-size: 16px;
  }
`;

root.render(
  <React.StrictMode>
    <StyledSnackbarProvider maxSnack={5}>
      <Router>
        <App />
      </Router>
    </StyledSnackbarProvider>
  </React.StrictMode>
);
