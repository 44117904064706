import {
  getParsedNftAccountsByOwner,
  resolveToWalletAddress,
} from "@nfteyez/sol-rayz";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey } from "@solana/web3.js";
import { fastConnection } from "../solanacodes/config";
import {
  decodeMetadata,
  getMetadataAccount,
} from "./contract/metadata.service";

const VERIFIED_CREATOR = process.env.REACT_APP_VERIFIED_CREATOR;

export const hashFromString = (s: string): number => {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const getMetadataFromTheMint = async (mint: string) => {
  const metadataAccount = await getMetadataAccount(mint);
  let metadataAccountData = undefined;
  for (let i = 0; i < 2; i++) {
    metadataAccountData = await fastConnection.getAccountInfo(
      new PublicKey(metadataAccount)
    );
    if (metadataAccountData) break;
  }
  if (!metadataAccountData) {
    return undefined;
  }
  const metadataDecoded = await decodeMetadata(metadataAccountData.data);
  if (!metadataDecoded) {
    return undefined;
  }
  let fullMetadata = undefined;
  for (let i = 0; i < 2; i++) {
    const res = await fetch(metadataDecoded.data.uri);
    fullMetadata = await res.json();
    if (fullMetadata) break;
  }
  return fullMetadata;
};

export const getAllNFTsGlobal = async (
  wallet: AnchorWallet,
  connection: Connection,
  useMint = false,
  stakedMints = []
) => {
  let mints = [];

  if (useMint) {
    mints = stakedMints;
  } else {
    const walletAddr = wallet.publicKey.toString();

    const address = walletAddr;
    const publicAddress = await resolveToWalletAddress({
      text: address,
    });
    const nftArray = await getParsedNftAccountsByOwner({
      publicAddress,
      connection,
    });

    let wallet_mints = nftArray
      .filter((nft) => {
        return (
          nft.data.creators &&
          nft.data.creators.length > 0 &&
          nft.data.creators[0].address === VERIFIED_CREATOR &&
          nft.data.creators[0].verified
        );
      })
      .map((x) => x["mint"]);
    mints = wallet_mints;
  }


  const mintMetadataPromiseList = mints.map(getMetadataFromTheMint);

  const mintMetadataList = await Promise.all(mintMetadataPromiseList);

  let nftInfoList: any = [];

  const placeholderImage = "";

  mints.forEach((mint, index) => {
    const mintFullMetadata = mintMetadataList[index];
    const isLegendary = mintFullMetadata.attributes.length === 1 ? true : false

    if (mintFullMetadata) {
      nftInfoList.push({
        name: mintFullMetadata.name,
        mint,
        code: hashFromString(mintFullMetadata.name),
        src:
          mintFullMetadata.image && mintFullMetadata.image.includes("http")
            ? mintFullMetadata.image
            : placeholderImage,
        tribe: isLegendary ? mintFullMetadata.attributes[0].value : mintFullMetadata.attributes[11].value,
        jpg: mintFullMetadata.properties.files[0].uri,
        gif: mintFullMetadata.properties.files[1].uri,
        mp4: mintFullMetadata.properties.files[2].uri,
      });
    }
  });

  return nftInfoList;
};
