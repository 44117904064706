import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <div className="footer-left-copy">&copy; Tight Knit Studios, LDA</div>
      </div>
      <div className="footer-right">
      </div>
      
    
    </footer>
  );
}

export default Footer;
