import { Grid, Typography, Box, Button } from "@mui/material";
import { BurnedNftCard } from "components/Cards/BurnedNftCard";
import { NftCard } from "components/Cards/NftCard";
import { useContractStore } from "contexts/ContractStoreProvider";
import React, { useState } from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import "./components/Cards/style.css";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import bs58 from "bs58";
import { useSnackbar } from "notistack";
import { fastConnection } from "solanacodes/utils";

function KnitHome() {
  const { connected } = useWallet();
  const { allNfts, isLoading } = useContractStore();
  const [value, setValue] = React.useState(0);
  const [selectedNft, setSelectedNft] = React.useState(0);
  // const [selectedMediaType, setSelectedMediaType] = React.useState("jpg");
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  if (allNfts.length > 0) console.log(allNfts[0].jpg, "allNfts[0].jpg");
  const [isGif, setIsGif] = useState(true);

  // console.log("media", selectedMediaType);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // This filters the NFTs by selected tribes
  function handleFilterClick(filter) {
    if (selectedFilters.includes(filter)) {
      // If the filter is already selected, remove it from the array
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Otherwise, add it to the array
      setSelectedFilters([...selectedFilters, filter]);
    }
  }

  const filteredNfts = allNfts.filter((nft) => {
    return selectedFilters.includes(nft.tribe);
  });

  const mediaTypes = ["jpg", "gif", "mp4", "zip"];

  const { signMessage, publicKey } = useWallet();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let name = allNfts[selectedNft]?.name;
  let mint = allNfts[selectedNft]?.mint;

  const downloadModelV2 = React.useCallback(
    async (type: string) => {
      let downloadingSnackbar = undefined;
      downloadingSnackbar = enqueueSnackbar("Downloading...", {
        variant: "info",
        persist: true,
      });

      try {
        const nft = allNfts[selectedNft];

        const url = nft[type];
        const fileName = `${name.split("#")[1]}.${type}`;
        console.log({ url, fileName });
        try {
          if (type === "zip") {
            const fileName = `${name.split("#")[1]}.${type}`;
            const url1 = nft["jpg"];
            const url2 = nft["mp4"];
            const url3 = nft["gif"];
            let jpgBlob = undefined;
            let mp4Blob = undefined;
            let gifBlob = undefined;
            const responseJpg = await fetch(
              " https://fast-brushlands-85485.herokuapp.com/" + url1,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/octet-stream",
                },
              }
            );
            jpgBlob = await responseJpg.blob();

            const responseMp4 = await fetch(
              " https://fast-brushlands-85485.herokuapp.com/" + url2,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/octet-stream",
                },
              }
            );
            mp4Blob = await responseMp4.blob();

            const responseGif = await fetch(
              " https://fast-brushlands-85485.herokuapp.com/" + url3,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/octet-stream",
                },
              }
            );
            gifBlob = await responseGif.blob();

            // Save it as zip file
            const zip = require("jszip")();
            zip.file(
              `${name.split("#")[1]}/${name.split("#")[1]}.png`,
              jpgBlob
            );
            zip.file(
              `${name.split("#")[1]}/${name.split("#")[1]}.mp4`,
              mp4Blob
            );
            zip.file(
              `${name.split("#")[1]}/${name.split("#")[1]}.gif`,
              gifBlob
            );
            await zip.generateAsync({ type: "blob" }).then((content) => {
              // Create blob link to download
              const url = window.URL.createObjectURL(new Blob([content]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);

              // Append to html link element page
              document.body.appendChild(link);

              // Start download
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);

              // Notify user
              enqueueSnackbar("Done", {
                variant: "success",
              });
            });
          } else {
            // Reference: https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
            await fetch(" https://fast-brushlands-85485.herokuapp.com/" + url, {
              method: "GET",
              headers: {
                "Content-Type": "application/octet-stream",
              },
            })
              .then((response) => response.blob())
              .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                // Notify user
                enqueueSnackbar("Done", {
                  variant: "success",
                });
              });
          }
        } catch (error) {
          enqueueSnackbar(`Error 004: Download failed!`, {
            variant: "error",
          });
          console.log({ error, errorMessage: error?.toString() });
          throw error;
        }
      } catch (error) {
        enqueueSnackbar(`Download failed, Please try again!`, {
          variant: "error",
        });
        console.log({ error, errorMessage: error?.toString() });
      } finally {
        if (downloadingSnackbar) closeSnackbar(downloadingSnackbar);
      }
    },
    [enqueueSnackbar, allNfts, selectedNft, name, closeSnackbar]
  );

  const downloadModel = React.useCallback(
    async (type: string) => {
      let latestBlockDetails = undefined;
      let latestSlotId = undefined;
      let downloadingSnackbar = undefined;
      downloadingSnackbar = enqueueSnackbar("Downloading...", {
        variant: "info",
        persist: true,
      });
      try {
        for (let i = 0; i < 10; i++) {
          try {
            const latestBlockDetailsLocal =
              await fastConnection.getLatestBlockhashAndContext("confirmed");
            latestSlotId = latestBlockDetailsLocal?.context?.slot;
            latestBlockDetails = latestBlockDetailsLocal?.value;
            console.log({ latestSlotId, latestBlockDetails });
            if (latestBlockDetails && latestSlotId) {
              break;
            }
          } catch (error) {
            console.log({ error });
            if (i === 9) {
              enqueueSnackbar(
                `Error 002: Something went wrong in getting Blockhash!`,
                {
                  variant: "error",
                }
              );
              throw error;
            }
          }
        }

        const encodedMessage = new TextEncoder().encode(
          `Verification Message: ${latestBlockDetails?.blockhash}`
        );
        let signature;
        try {
          signature = await signMessage(encodedMessage);
        } catch (e) {
          enqueueSnackbar(
            `Error 003: Something went wrong in signing verification message!`,
            {
              variant: "error",
            }
          );
          console.log({ e, errorMessage: e?.toString() });
          throw e;
        }
        const pub = publicKey?.toBase58();
        const res = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/download-file`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              signature: bs58.encode(signature),
              publicKey: pub,
              slotId: latestSlotId,
              mint,
              type: type,
            }),
          }
        );
        const { url, result } = await res.json();
        const fileName = `${name.split("#")[1]}.${type}`;
        try {
          // Reference: https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
          await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/octet-stream",
            },
          })
            .then((response) => response.blob())
            .then((blob) => {
              // Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);

              // Append to html link element page
              document.body.appendChild(link);

              // Start download
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);

              // Notify user
              enqueueSnackbar("Done", {
                variant: "success",
              });
            });
        } catch (error) {
          enqueueSnackbar(`Error 004: Download failed!`, {
            variant: "error",
          });
          console.log({ error, errorMessage: error?.toString() });
          throw error;
        }
      } catch (error) {
        enqueueSnackbar(`Download failed, Please try again!`, {
          variant: "error",
        });
        console.log({ error, errorMessage: error?.toString() });
      } finally {
        if (downloadingSnackbar) closeSnackbar(downloadingSnackbar);
      }
    },
    [enqueueSnackbar, publicKey, mint, name, signMessage, closeSnackbar]
  );

  if (!allNfts || isLoading) {
    return (
      <>
        <Header />
        <div className="h-[80vh] flex text-center gap-4 justify-center items-center">
          <svg
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <path
              opacity="0.2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              fill="currentColor"
            />
            <path
              d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
              fill="currentColor"
            />
          </svg>
          <h1 className="title">Searching for NFTs</h1>
        </div>
        <Footer />
      </>
    );
  }
  if (!connected) {
    return (
      <>
        <Header />
        <div className="circle"></div>
        <div className="grid lg:grid-cols-2 mx-6 md:mx-24 gap-4 lg:gap-16 py-8 items-center min-h-[80vh]">
          <div>
            <div className="rounded-2xl bg-[#e9dbc7] flex justify-center items-center z-20 relative shadow-2xl w-full h-full overflow-clip hover:scale-105 transition duration-200 hover:cursor-pointer">
              {/* <div className="bg-white opacity-30 border-4 border-[#e9dbc7] rounded-full absolute w-72 h-72 -right-10 bottom-12 z-10 shadow-lg animate-pulse circle" />
              <div className="bg-white opacity-30 border-4 border-[#e9dbc7] rounded-full absolute w-72 h-72 -left-10 top-12 z-10 shadow-lg animate-pulse circle-reverse" /> */}
              {/* <div className="absolute -left-10 top-12 z-10 animate-spin-slow hover:">
                <img src="/button1.png" alt="Tribe" />
              </div>
              <div className="absolute -right-10 top-12 z-10 animate-spin-slow hover:">
                <img src="/button2.png" alt="Tribe" />
              </div>
              <div className="absolute -left-10 bottom-12 z-10 animate-spin-slow hover:">
                <img src="/button3.png" alt="Tribe" />
              </div> */}
              <img
                src={"/knittable_nft_swap.gif"}
                alt="Tribe"
                className="object-cover w-full lg:h-full"
              />
              {/* <img src="/evo.png" alt="Tribe" /> */}
              {/* <img src="/neo.png" alt="Tribe" /> */}
            </div>
          </div>
          <div className="grid gap-6 text-center">
            <h1 className="title title-welcome">Welcome to Knittables.</h1>
            <h2 className="subtitle">
              A tight-knit Tribe of 10,000 3D-animated NFTs conquering the
              Solana Blockchain.
            </h2>
            <div className="flex flex-col sm:flex-row gap-2 justify-center items-center">
              <div className="cta-connect">
                <WalletMultiButton />
              </div>
              <div>
                <a
                  className="cta hover:opacity-50 max-w-max duration-400 transition"
                  href="https://magiceden.io/marketplace/knittables"
                  target="_blank"
                  rel="noreferrer"
                >
                  Buy on Magic Eden
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
  if (allNfts.length == 0) {
    return (
      <>
        <Header />
        <div className="flex flex-col text-center mx-6 md:mx-24 py-8 justify-center h-[80vh]">
          <h1 className="title">You have no Knittables in your wallet.</h1>
          <div>
            <a
              className="cta hover:opacity-50 max-w-max mx-auto duration-400 transition"
              href="https://magiceden.io/marketplace/knittables"
              target="_blank"
              rel="noreferrer"
            >
              Buy on Magic Eden
            </a>
          </div>
        </div>
        <Footer />
      </>
    );
  }
  return (
    // allNfts.length && (
    <>
      <Header />
      <div className="grid mx-6 md:mx-12 lg:mx-24 text-center gap-4 justify-center items-center">
        {/* <h1 className="title">
          Select your Knittable NFT to get all the assets
        </h1> */}
        <div className="min-h-[50vh] grid lg:grid-cols-2 gap-8 justify-center items-center px-6 my-12">
          <div className="relative flex flex-col-reverse lg:flex-col lg:items-center">
            <div className="relative h-full 2xl:h-96 hover:scale-105 transition duration-200 mx-auto">
              <img
                src={
                  isGif ? allNfts[selectedNft].src : allNfts[selectedNft].jpg
                }
                alt={allNfts[selectedNft].name}
                className="rounded-xl shadow-lg hover:cursor-pointer object-cover h-full"
              />
            </div>
            <div className="flex gap-2 paragraph items-center lg:absolute -bottom-14">
              <p
                className={`${
                  isGif ? "" : "opacity-50"
                } duration-500 transition`}
              >
                GIF
              </p>
              <label className="relative h-8 w-14 cursor-pointer">
                <input
                  type="checkbox"
                  className="peer sr-only"
                  onClick={() => setIsGif(!isGif)}
                />

                <span className="absolute inset-0 rounded-full bg-[#B1ADA5] transition peer-checked:bg-[#e0d8c8]"></span>

                <span className="absolute inset-0 m-1 h-6 w-6 rounded-full bg-white transition peer-checked:translate-x-6"></span>
              </label>
              <p
                className={`${
                  isGif ? "opacity-50" : ""
                } duration-500 transition`}
              >
                JPG
              </p>
            </div>
          </div>
          <div className="grid gap-6">
            <p className="paragraph !text-lg bottom-4">
              {allNfts[selectedNft].name}
            </p>
            <div className="grid grid-cols-2 gap-4 paragraph">
              {mediaTypes.map((mediaType, index) => {
                return (
                  <div
                    className={`bg-[#e0d8c8] rounded-2xl uppercase hover:opacity-50 transition duration-200 relative hover:cursor-pointer overflow-hidden h-28 lg:h-24 grid items-center justify-center shadow-md`}
                    onClick={() => downloadModelV2(mediaType)}
                    key={index}
                  >
                    <p className="absolute -left-3 -top-2 text-3xl opacity-10 overflow-clip">
                      0{index + 1}
                    </p>
                    <p className="font-extrabold text-3xl">{mediaType}</p>
                  </div>
                );
              })}
              {/* <a
                className={`bg-[#e0d8c8] rounded-2xl uppercase hover:opacity-50 transition duration-200 relative hover:cursor-pointer overflow-hidden h-28 lg:h-24 grid items-center justify-center shadow-md text-knittables-gray hover:text-knittables-gray hover:no-underline`}
                href={`https://solscan.io/token/${mint}`}
                target="_blank"
                rel="noreferrer"
              >
                <p className="absolute -left-3 -top-2 text-3xl opacity-10 overflow-clip">
                  04
                </p>
                <p className="font-extrabold text-xl">solscan</p>
              </a> */}
              {/* <button
                className="bg-[#a29682] rounded-xl py-2 uppercase hover:opacity-50 focus:outline-none focus:ring-4 focus:ring-gray-600 text-white transition duration-200"
                onClick={() => downloadModel(selectedMediaType)}
              >
                download
              </button> */}
            </div>
          </div>
        </div>
        <div className="paragraph my-4 !text-xl sm:!text-base">
          You have{" "}
          <div>
            <span className="bg-white text-black p-1 rounded-md mx-1">
              {allNfts.length}
            </span>
          </div>{" "}
          Knittable NFTs.
        </div>
        {/* <div className="nfts">
          {allNfts.map((nft) => {
            return (
              <NftCard
                name={nft.name}
                image={nft.src}
                mint={nft.mint}
                burned={false}
              />
            );
          })}
        </div> */}
        <div className="flex gap-4 paragraph">
          <button
            className={`${
              selectedFilters.includes("Proto")
                ? "bg-[#e0d8c8] border-2 border-[#e0d8c8]"
                : "bg-[#faf8f5] text-[#d9c4a0] border-[#e0d8c8] border-2"
            } py-2 rounded-2xl hover:cursor-pointer focus:outline-none hover:scale-105 duration-200 transition w-20`}
            onClick={() => handleFilterClick("Proto")}
          >
            Proto
          </button>
          <button
            className={`${
              selectedFilters.includes("Evo")
                ? "bg-[#e0d8c8] border-2 border-[#e0d8c8]"
                : "bg-[#faf8f5] text-[#d9c4a0] border-[#e0d8c8] border-2"
            } py-2 rounded-2xl hover:cursor-pointer focus:outline-none hover:scale-105 duration-200 transition w-20`}
            onClick={() => handleFilterClick("Evo")}
          >
            Evo
          </button>
          <button
            className={`${
              selectedFilters.includes("Neo")
                ? "bg-[#e0d8c8] border-2 border-[#e0d8c8]"
                : "bg-[#faf8f5] text-[#d9c4a0] border-[#e0d8c8] border-2"
            } py-2 rounded-2xl hover:cursor-pointer focus:outline-none hover:scale-105 duration-200 transition w-20`}
            onClick={() => handleFilterClick("Neo")}
          >
            Neo
          </button>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 border-4 rounded-xl p-12 border-[#d5cab9] min-w-full">
          {selectedFilters.length === 0 && (
            <>
              {allNfts.map((nft, index) => {
                return (
                  <div
                    className={`${
                      selectedNft === index
                        ? "border-4 border-[#d5cab9]"
                        : "border-4 border-[#e0d8c8]"
                    } bg-[#e0d8c8] p-2 grid items-center justify-center text-center gap-2 hover:cursor-pointer rounded-xl shadow-xl hover:animate-pulse relative duration-200 transition`}
                    onClick={() => setSelectedNft(index)}
                  >
                    <img
                      src={
                        nft.tribe === "Proto"
                          ? "/button_proto.png"
                          : nft.tribe === "Evo"
                          ? "/button_evo.png"
                          : "/button_neo.png"
                      }
                      alt={nft.name}
                      className="absolute right-4 top-4 w-6 h-6 animate-spin-slow z-20"
                    />
                    <div className="rounded-xl relative shadow-md">
                      <img
                        src={nft.src}
                        alt={nft.name}
                        className="mx-auto rounded-xl h-full"
                      />
                    </div>
                    <div className="grid py-2 items-center gap-2">
                      {/* <hr className="my-0 w-full h-[0.1rem] rounded border-0 bg-[#af9572]" /> */}
                      <p className="paragraph uppercase">{nft.name}</p>
                      {/* <hr className="my-0 w-full h-[0.1rem] rounded border-0 bg-[#af9572]" /> */}
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {filteredNfts.length === 0 && selectedFilters.length >= 1 && (
            <div className="paragraph !text-2xl py-24">No NFTs</div>
          )}
          {filteredNfts.map((nft, index) => {
            return (
              <div
                className={`${
                  selectedNft === index
                    ? "border-4 border-[#d5cab9]"
                    : "border-4 border-[#e0d8c8]"
                } bg-[#e0d8c8] p-2 grid items-center justify-center text-center gap-2 hover:cursor-pointer rounded-xl shadow-xl hover:animate-pulse relative duration-200 transition`}
                onClick={() => setSelectedNft(index)}
              >
                <img
                  src={
                    nft.tribe === "Proto"
                      ? "/button_proto.png"
                      : nft.tribe === "Evo"
                      ? "/button_evo.png"
                      : "/button_neo.png"
                  }
                  alt={nft.name}
                  className="absolute right-4 top-4 w-6 h-6 animate-spin-slow z-20"
                />
                <div className="rounded-xl relative shadow-md">
                  <img
                    src={nft.src}
                    alt={nft.name}
                    className="mx-auto rounded-xl h-full"
                  />
                </div>
                <div className="grid py-2 items-center gap-2">
                  {/* <hr className="my-0 w-full h-[0.1rem] rounded border-0 bg-[#af9572]" /> */}
                  <p className="paragraph uppercase">{nft.name}</p>
                  {/* <hr className="my-0 w-full h-[0.1rem] rounded border-0 bg-[#af9572]" /> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
  // );
}
export default KnitHome;
