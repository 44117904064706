import React from 'react';
import { Container, Row, Col, List } from 'bootstrap-4-react';
import classNames from 'classnames';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const Header = () => {
  return (
    <header className="header my-2 sm:my-0">
      {/* <div className="header-bar">
        KNITTABLES
      </div> */}
      <div className="header-container items-center">
        <div className="header-center">
          <img
            src='/logo_brown.png'
            alt="Knittables Logo"
          />
        </div>
        <div className="header-right flex flex-col sm:flex-row gap-2">
          <div className='flex gap-2 items-center'>
            <a href="https://twitter.com/theKnittables" target="_blank" rel="noreferrer">
              <img className="icon-twitter hover:scale-150 duration-300 transition" src="/Twitter_icon.svg" alt='Twitter' />
            </a>
            <a href="https://discord.com/invite/knittables-official" target="_blank" rel="noreferrer">
              <img className="icon-discord hover:scale-150 duration-300 transition" src="/Discord_icon.png" alt='Discord' />
            </a>
            <a href="https://docs.google.com/presentation/d/177X9OLvgrwoOzJDY806dwW9y3PcRYBBemuUidJjPFdw/edit" className='text-black hover:text-black focus:outline-none hover:scale-150 duration-300 transition' target="_blank" rel="noreferrer">
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 18H17V16H7V18Z" fill="black" />
                <path d="M17 14H7V12H17V14Z" fill="black" />
                <path d="M7 10H11V8H7V10Z" fill="black" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                  fill="black"
                />
              </svg>
            </a>
            <a href="https://linktr.ee/theknittables" target="_blank" rel="noreferrer">
              <img className="icon-linktree sm:mr-2 hover:scale-150 duration-300 transition" src="/linktree_icon.png" alt='Linktree' />
            </a>
          </div>
          <div className='cta-connect'>
            <WalletMultiButton />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
