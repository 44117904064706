import React from "react";
import KnitHome from "KnitHome";
function Home() {
  return (
    <>
      <KnitHome />
    </>
  );
}

export default Home;
